/* eslint-disable no-useless-escape */ /* eslint-disable */

<template>
  <v-dialog v-model="showDialog" scrollable :max-width="Body != '' ? 800 : 300">
    <v-card>
      <v-card-title class="text-h5"> Show Email Body </v-card-title>
      <v-card-text>
        <div v-if="Body != ''" v-html="Body"></div>
        <div v-else>no template for showing.</div>
        <v-btn @click="exportHtml">export html</v-btn>
        <!-- <v-row>
          <v-col cols="12" md="12">
            <EmailEditor
              :appearance="appearance"
              :min-height="minHeight"
              :project-id="projectId"
              :display-mode="displayMode"
              :locale="locale"
              :tools="tools"
              :options="options"
              ref="emailEditor"
              v-on:load="editorLoaded"
              v-on:ready="editorReady"
            />
          </v-col>
        </v-row> -->
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="closeDialog"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowTMDialogue",
  watch: {
    showDialog(val) {
      val || this.closeDialog();
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          Email_Body: "",
        };
      },
    },
  }, // ["data"],
  components: {},
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    console.log(this.data)
  },
  computed: {
    Body() {
      return this.data
        ? this.data.Body?.length > 50
          ? JSON.parse(this.data?.Body).html
          : ""
        : "";
    },
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit("close");
    },
    save(data) {
      let obj = {
        Body: JSON.stringify({ html: data.html, design: data.design }),
        State: "Active",
        Name: "Test1",
        ID_User: "USR2020259",
      };
      this.$API.email
        .addEmail(obj)
        .then((response) => {
          this.$toasted.success(response.message);
          console.log("Done");
        })
        .catch(() => {});
    },
    exportHtml(){}
  },
};
</script>

<style scoped>
.unlayer-editor {
  height: 1000px !important;
}
.unlayer-editor iframe {
  min-width: auto !important;
}
</style>

<template>
    <v-expansion-panel>
        <v-expansion-panel-header>
            Búsqueda de módulo de plantilla
            <template v-slot:actions>
                <v-btn class="mr-5" @click.native.stop="reloadp++" icon small>
                    <v-icon color="primary">mdi-reload</v-icon>
                </v-btn>
                <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                    <v-icon color="error">mdi-close</v-icon>
                </v-btn>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content :key="reloadp">
            <v-card>
                <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                    <v-tabs-slider color="red"></v-tabs-slider>
                    <v-tab href="#tab-1">
                        Búsqueda de módulo de plantilla
                        <v-icon>mdi-email-search</v-icon>
                    </v-tab>
                    <v-tab href="#tab-2">
                        Agregar/Editar módulo de plantilla
                        <v-icon>mdi-email-plus</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" touchless>
                    <v-tab-item value="tab-1">
                        <v-card flat>
                            <v-card-text class="pa-1">
                                <v-card>
                                    <GeneralFilter class="background border-box" :filterEndpoint="$API.TM.getTMFiltered" :search="search" @emptyFilter="getAllTemplateModule" @filtered="filterHandler">
                                        <DxDataGrid class="isScrolledByGeneralFilter" :data-source="TM" key-expr="Id" :show-row-lines="showRowLines"
                                            :show-borders="showBorders" :row-alternation-enabled="rowAlternationEnabled">
                                            <DxScrolling row-rendering-mode="virtual" />
                                            <DxPaging :page-size="10" />
                                            <DxColumn :width="80" cell-template="show-template" caption=""></DxColumn>
                                            <DxColumn data-field="Id" caption="ID"></DxColumn>
                                            <DxColumn data-field="Name" caption="Name"></DxColumn>
                                            <DxColumn data-field="Body" caption="Body" cell-template="body" />
                                            <template #body="{ data }">
                                                <v-icon small class="mr-2" color="primary" @click="showTemplate(data.data)">mdi-eye</v-icon>
                                            </template>
                                            <DxColumn data-field="ID_User" caption="User ID"></DxColumn>
                                            <DxColumn data-field="State" caption="State" cell-template="state" />
                                            <template #state="{ data }">
                                                <v-chip small :color="
                                                  data.data.State === 'Active' || data.data.State === 1 || data.data.State === '1' ? 'success' : 'grey'
                                                ">{{
                              data.data.State === "Active" || data.data.State === 1 || data.data.State
                              === "1" ? "Active" : "Inactive"
                              }}</v-chip>
                                            </template>
                                            <DxColumn data-field="SystemDateInsert" caption="Created At"
                                                cell-template="created-at" />
                                            <template #created-at="{ data }">
                                                <span>
                                                    {{
                                                    moment(data.data.SystemDateInsert).format("DD MMM YYYY, h:mm A")
                                                    }}
                                                </span>
                                            </template>
    
                                            <DxColumn data-field="SystemDateUpdate" caption="Updated At"
                                                cell-template="updated-at" />
                                            <template #updated-at="{ data }">
                                                {{
                                                moment(data.data.SystemDateUpdate).format("DD MMM YYYY, h:mm A")
                                                }}
                                            </template>
                                            <template #show-template="{ data }">
                                                <div>
                                                    <a href="javascript:void(0)" @click="templateSelected(data)">
                                                        <v-icon small class="mr-2" color="primary">mdi-eye</v-icon>
                                                    </a>
                                                    <a href="javascript:void(0)" @click.prevent="
                                                      emailToDelete = data.data;
                                                      deleteDialog = true;
                                                    ">
                                                        <v-icon small color="error">mdi-delete</v-icon>
                                                    </a>
                                                </div>
                                            </template>
                                        </DxDataGrid>
                                    </GeneralFilter>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2" >
                        <v-card  flat>
                <v-card-text class="pa-1">
                  <v-card  class="white pa-8">
                                    <ManageTemplateModule @reloadData="tab=1;cleanTM();getAllTemplateModule(); "  @clean="cleanTM" :key="rebuild" :win="win" :TM="TMSelected" :editMood="editmode"></ManageTemplateModule>
                             </v-card>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>

            </v-card>
        </v-expansion-panel-content>

        <ShowTMDialogue ref="showDialog" :data="emailToShow" @close="closeShowDialog"></ShowTMDialogue>

        <v-dialog v-model="deleteDialog" max-width="290">
            <DeleteTemplateModule :data="emailToDelete" @close="closeDialog"></DeleteTemplateModule>
        </v-dialog>
    </v-expansion-panel>
</template>
  
<script>
import { DxDataGrid, DxColumn, DxScrolling, DxPaging } from "devextreme-vue/data-grid";
import DeleteTemplateModule from "../template_module/DeleteTemplateModule.vue";
import ShowTMDialogue from "../template_module/showTMDialogue.vue";
import { mapGetters } from "vuex";
import { bus } from '../../../main';
import ManageTemplateModule from "./manageTempateModule.vue";
import GeneralFilter from "../../../components/GeneralFilter.vue";

export default {
    name: "TemplateModule",
    components: {
        DeleteTemplateModule,
        ShowTMDialogue,
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxPaging,
        GeneralFilter,
        ManageTemplateModule,
    },
    props: ["win"],
    data() {
        return {
            search: {},
            tab: null,
            TM: [],
            deleteDialog: false,
            emailToDelete: null,
            emailToShow: {
                Email_Body: "",
            },
            showRowLines: true,
            showBorders: true,
            rowAlternationEnabled: true,
            reloadp: 0,
            TMSelected: {
                Name: "",
                Body: {},
                ID: "",
                State: "",
                Type: "",
                ID_User: "",
        
            },
            editmode: false,
        };
    },
    mounted() {
        bus.$on('templateModule', () => {
            this.getAllTemplateModule();
        })
        this.getAllTemplateModule();
    },
    computed: {
        ...mapGetters(["reloadData"]),
    },
    methods: {
        getAllTemplateModule() {
            this.$API.TM.getTM().then((response) => {
                this.TM = response;
            });
        },
        filterHandler(response) {
            this.TM = response;
        },
        showTemplate(data) {
            this.emailToShow = Object.assign({}, data);
            this.$refs.showDialog.showDialog = true;
        },
        closeShowDialog() {
            this.emailToShow = Object.assign({}, { Email_Body: "" });
            this.$refs.showDialog.showDialog = false;
        },
        closeDialog(payload) {
            this.emailToDelete = null;
            this.deleteDialog = false;
            if (payload.isDeleted) {
                this.getAllTemplateModule();
            }
        },

        templateSelected(data) {
           /*  this.$store.dispatch("addWindow", {
                name: "AddTemplateModule",
                component: "AddTemplateModule",
                unique: true,
                meta: { TM: data.data, editMood: true },
            }); */
            this.TMSelected = data.data;
            this.editmode = true;
            this.tab = "tab-2";
            this.rebuild++;
        },
        cleanTM() {
            this.TMSelected =  {
                Name: "",
                Body: {},
                ID: "",
                State: "",
                Type: "",
                ID_User: "",
        
            };
            this.editmode = false;
            this.rebuild++;
            this.reloadp++;
        },
    },
};
</script>
  
<style scoped>
    .border-box {
        border-radius: 15px !important;
    }
    .background {
        background: white !important;
        color: white;
    }
</style>
  
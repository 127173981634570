<template>
    <ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(save)">
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                        <v-text-field outlined dense :error-messages="errors" v-model="form.Name" label="Nombre">
                        </v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                        <span class="fixing">
                            <v-autocomplete outlined dense  :error-messages="errors" :items="Type" item-text="name" item-value="value" attach
                                label="Tipo" v-model="form.Type"></v-autocomplete>
                        </span>
                    </ValidationProvider>
                    
                </v-col>
                <v-col cols="12" sm="12" md="6" class="pb-2" v-if="form.Body">
                    <v-btn @click="open"  :color="!isEmpty(form.Body) ? 'btnsave' : 'grey'">{{
                            !isEmpty(form.Body)
                                ? editMood
                                    ? "Usted tiene una plantilla, haga clic para editar."
                                    : "Tienes una nueva plantilla."
                                : "No tienes plantilla, debes añadirla."
                    }}</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="pb-2" style="padding-bottom: 13px;" v-else>
                    <v-btn @click="open" class="btnsave">No tienes plantilla, debes añadirla..</v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <ValidationProvider name="State" rules="required" v-slot="{ errors }">
                        <v-select outlined dense  :error-messages="errors" :items="states" item-text="name" item-value="value" attach
                            label="Estado" v-model="form.State"></v-select>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-row justify="end">
                        <v-btn class="btnclean mr-1" @click="$emit('clean')">Limpiar</v-btn>
                     
                        <v-btn color="btnsave" type="submit" :loading="loading_btn"
                            :disabled="invalid || isEmpty(form.Body) || loading_btn">Guardar</v-btn>
                    </v-row>
                </v-col>
            </v-row>

            <v-dialog v-model="dialog" max-width="auto" scrollable>
                <v-card height="inherit">
                    <v-card-title class="text-h5"> {{ formTitle }}</v-card-title>
                    <v-card-text v-if="form.Body">
                        <v-row>
                            <v-col cols="10" md="10">
                                <EmailEditor :appearance="appearance" :min-height="minHeight" :project-id="projectId"
                                    :display-mode="displayMode" :locale="locale" :tools="tools" :options="options"
                                    ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady"
                                    v-on:image:uploaded="imageUploaded"
                                    />
                            </v-col>
                            <v-col cols="2 mt-4" md="2">
                                <h4 class="varibles_text">Variables para usar</h4>
                                
                                <div v-if=" form.Type=='Grooming' || form.Type=='Surgery' || form.Type=='Medications' || form.Type=='Prescription' || form.Type=='Birthday' || form.Type=='Notification' || form.Type=='Invoices'">
                                    <h6 class="varibles_text" v-for="(varforTemplate, index) in varforTemplates" :key="`${index}-varforTemplates`">@{{ varforTemplate }}</h6>
                                </div>
                                <div v-else>
                                    <h6 class="varibles_text" v-for="(value, name, index) in prescriptionJSON" :key="`${index}-prescription`">@{{ value }}</h6>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="btndelete" text @click="close"> Cerrar </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="btnsave" text @click="exportData">Guardar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
        </v-form>
    </ValidationObserver>
</template>
  
<script>
import _ from "lodash";
import { EmailEditor } from "vue-email-editor";

import Json from "../../../assets/Json/printTemplate.json";
import Alerts from "@/components/Alerts.vue";

export default {
    name: "ManageTemplateModule",
    props: ["TM", "win", "editMood"],
    components: { EmailEditor,
        Alerts
        },
    data() {
        return {
            alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
            form: {},
            dialog: false,
            //   editMood: false,
            loading_btn: false,
            states: [
                { name: "Active", value: "1" },
                { name: "Inactive", value: "0" },
            ],
            Type: [
                { name: "Prescripcion", value: "Prescription" },
                { name: "Notificacion", value: "Notification" },
             //   { name: "Laboratorio", value: "LaboratoryTest" },
                { name: "Vacunas/medicamentos", value: "Medications" },
                { name: "Peluqueria", value: "Grooming" },
                { name: "Cumpleaños", value: "Birthday" },
                { name: "Cirugias", value: "Surgery" },
                { name: "Facturas", value: "Invoices" },
            ],
            Var: [
                { name: "Grooming", value: ['Patientname', 'Date'] },
                { name: "Medications", value: ['Patientname', 'Date','medicineName']},
                { name: "Surgery", value: ['Patientname','SurgeryDate']},
                { name: "Prescription", value: ['Patientname', 'NextDate','PrescriptionValue']},
                { name: "Notification", value: ['Patientname', 'Date','customer']},
               // { name: "LaboratoryTest", value: ['Patientname', 'Date']},
                { name: "Birthday", value: ['Patientname', 'years','customer']},
                { name: "Invoices", value: ['customer', 'date','codigoGeneracion']},
            ],
            Body: {
                html: "",
                design: "",
            },
            defaultTemp: {
                html: "",
                design: "",
            },
            minHeight: "1000px",
            locale: "en",
            projectId: 0, // replace with your project id
            displayMode: "email",
            tools: {
                // disable image tool
                image: {
                    enabled: false,
                },
            },
            options: {},
            appearance: {
                // theme: 'dark',
                panels: {
                    tools: {
                        dock: "right",
                    },
                },
            },
            isHeader: [],
            isFooter: [],
            prescriptionJSON:{},
        };
    },
    watch: {
        dialog(val) {
            val || this.close;
        },
    },
    computed: {
        formTitle() {
            return this.editMood ? "Change Current Template" : "Add New Template";
        },
        varforTemplates(){
            console.log(this.form.Type);
            return this.Var.find(x => x.name === this.form.Type).value;
        }
    },
    mounted() {
        if(JSON.parse(localStorage.getItem("user")).businesstype == 'accounting_and_bill'){
            this.Type = [{ name: "Facturas", value: "Invoices" }]
        }

         this.fetchCurrentTemp();
        this.prescriptionJSON = Json[0][this.form.Type];
        // this.getHeaderFooter();
    },
    methods: {
        closeAlert() {
      this.alert.show = false;

      if(this.alert.options == "reloadandredirect") this.$emit("reloadData");

      
    },
    acceptAlert() {
      this.alert.show = false;

    
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
        // getHeaderFooter() {
        //     this.$API.HF.getHF().then((data) => {
        //     })
        // },
        fetchCurrentTemp() {
            let { Id, Name, State, Body, Type } = JSON.parse(
                JSON.stringify(this.win.meta ? this.win.meta.TM : this.TM)
            );
            if (this.win.meta ? this.win.meta.editMood : this.editMood) {
                this.editMood = true;
                this.form.Id = Id;
                this.form.Name = Name;
                this.form.Body = JSON.parse(Body);
                this.form.State = State === "Active" || State === 1 || State === "1" ? "1" : "0";
                this.form.ID_User = JSON.parse(localStorage.getItem("user")).id;
                this.form.Type = Type;
            } else {
                this.form.Id = "";
                this.form.Name = "";
                this.form.Body = Body;
                this.form.State = "";
                this.form.ID_User = JSON.parse(localStorage.getItem("user")).id;
                this.form.Type = "";
            }
        },
        isEmpty(data) {
           
            return _.isEmpty(data);
        },
        reloadData(payload) {
            this.$emit("reloadData", payload);
        },
        open() {
            this.editMood
                ? (this.Body = JSON.parse(JSON.stringify(this.form.Body)))
                : "";
 
            this.dialog = true;
        },
        close() {
            this.Body = Object.assign({}, this.defaultTemp);
            this.dialog = false;
        },

        editorLoaded() {
            console.log("editorLoaded");
            this.editMood && this.$refs.emailEditor.editor.loadDesign(this.Body.design);
            // Pass your template JSON here
        },
        // called when the editor has finished loading
        editorReady() {
            console.log("editorReady");
        },
        imageUploaded(data) {
            console.log("imageUploaded", data);
        },
        exportData() {
            this.$refs.emailEditor.editor.exportHtml((data) => {
                this.form.Body = { design: data.design, html: data.html };
                console.log("exportHtml", data);
                this.close();
            });
        },
        async save() {
            this.$refs.observer.validate();
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
                this.loading_btn = true;
                if (this.editMood) {
                    let obj = {
                        Id: this.form.Id,
                        Body: JSON.stringify({
                            html: this.form.Body.html,
                            design: this.form.Body.design,
                        }),
                        State: this.form.State,
                        Name: this.form.Name,
                        ID_User: this.form.ID_User,
                        Type: this.form.Type
                    };
                    this.$API.TM
                        .updateTM(obj)
                        .then((response) => {
                            this.loading_btn = false;
                            this.$refs.observer.reset();
                            console.log(response);
                            this.showAlert("success", "Exito","Plantilla actualizada correctamente",'reloadandredirect');
                           
                            /* this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid });
                            this.$store.dispatch('addWindow', {
                                name: 'TemplateModule',
                                component: 'TemplateModule',
                                unique: true,
                                meta: { customerToShow: this.win.meta.customerToShow }
                            })
                            bus.$emit('templateModule', 'Need Refresh1');
                            this.reloadData("email"); */
                        })
                        .catch((err) => {
                            this.loading_btn = false;
                            console.log(err);
                            this.showAlert("danger", "Error","Error al agregar la plantilla")

                        });
                } else {
                    let obj = {
                        Body: JSON.stringify({
                            html: this.form.Body.html,
                            design: this.form.Body.design,
                        }),
                        State: this.form.State,
                        Name: this.form.Name,
                        ID_User: this.form.ID_User,
                        Type: this.form.Type
                    };
                    this.$API.TM
                        .addTM(obj)
                        .then((response) => {
                            this.loading_btn = false;
                            this.$refs.observer.reset();
                            console.log(response);
                            this.showAlert("success", "Exito","Plantilla agregada correctamente",'reloadandredirect');
                            this.$emit("reloadData");
                            /* setTimeout(() => {
                                this.$store.dispatch('addWindow', {
                                    name: 'TemplateModule',
                                    component: 'TemplateModule',
                                    unique: true,
                                    meta: { customerToShow: obj }
                                })
                                this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid });
                                bus.$emit('templateModule', 'Need Refresh1');
                                this.reloadData("email");
                            }, 1000) */
                        })
                        .catch((err) => {
                            this.loading_btn = false;
                            console.log(err);
                            this.showAlert("danger", "Error","Error al agregar la plantilla")
                        });
                }
            }
        },
    },
};
</script>
  
<style scoped>
.temp-dimensions {
    max-width: 100px;
    max-height: 300px;
}

.unlayer-editor {
    height: 1000px !important;
}

.unlayer-editor iframe {
    min-width: auto !important;
}

::v-deep .unlayer-editor>iframe {
    min-width: auto !important;
}

.varibles_text{
    padding: 5px;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}

</style>

<style>
/* .fixing .menuable__content__active .v-list{
    max-height: 60px !important;
} */

</style>
  
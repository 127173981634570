<template>
    <v-card>
        <v-card-title class="text-h5">
            Are you sure you want to delete  ?
        </v-card-title>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="deleteData">
                Yes
            </v-btn>
            <v-btn color="green darken-1" text @click="$emit('close', {isDeleted: false})">
                No
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "DeleteTemplateModule",
        props: ['data'],
        methods: {
            deleteData() {
                this.$API.TM.deleteTM(this.data.Id)
                    .then((response) => {
                        this.$toasted.success(response.message);
                        this.$emit('close', {isDeleted: true})
                    })
            }
        }
    }
</script>

<style scoped>

</style>